var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialog
    ? _c(
        "v-dialog",
        {
          attrs: {
            "retain-focus": false,
            scrollable: "",
            lass: "no-x",
            width: "40vw"
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "no-x", attrs: { loading: _vm.isLoading } },
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [_vm._v("Credentials")]),
                              _c(
                                "v-card-text",
                                [
                                  _c("domain-form-access", {
                                    ref: "domainFormAccess"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _vm.mode !== _vm.DialogMode.VIEW
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled:
                              !_vm.accessFormData.isValid || _vm.isLoading
                          },
                          on: { click: _vm.updateDomainAccess }
                        },
                        [_vm._v(" Save ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }