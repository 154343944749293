var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { staticClass: "mb-4" },
        [
          _c(
            "v-toolbar-title",
            [
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.viewDialog } },
                [_c("v-icon", [_vm._v(" mdi-information-outline ")])],
                1
              )
            ],
            1
          ),
          _c("v-toolbar-title", { staticClass: "font-weight-light" }, [
            _vm._v("My Domain Workspaces")
          ]),
          _c("v-spacer"),
          _c("div", [_vm._t("content")], 2),
          _vm._t("default")
        ],
        2
      ),
      _c("help-tooltip-dialogs")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }