


















































import { defineComponent, ref, reactive, toRefs } from '@vue/composition-api';
import DomainFormAccess, { useAccessForm } from '@/views/app/domains/DomainFormAccess.vue';

import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';
import {
  DomainModel,
  useDomainApi,
  DomainCredentialModel,
  DomainAccessInput
} from '@/module/api/domain';
import { FormOptions } from '@/utils';
import { UserMessageError } from '@/lib/user-message-error';

const toast = createToastInterface({ maxToasts: 3 });

const {
  setFormOptions: setAccessFormOptions,
  getFormData: getAccessFormData,
  validateFormData: validateAccessFormData,
  reset: resetAccessForm,
  form: accessFormData
} = useAccessForm();

const { updateDomainAccessCredentials } = useDomainApi();

export enum DialogMode {
  VIEW = 'view',
  EDIT = 'edit',
  ADD = 'add'
}

const emptyState = {
  dialog: false,
  title: 'Add Domain',
  mode: 'edit',
  domainUuid: '',
  isLoading: false
};

const state = reactive({ ...emptyState });
const domainFormMeta = ref<any>();
const domainFormAccess = ref<any>();
const domainFormProvisioning = ref<any>();

const setFormOptions = (formOptions: FormOptions) => {
  setAccessFormOptions(formOptions);
};

const updateDomainAccess = async () => {
  state.isLoading = true;
  const formData: DomainAccessInput = getAccessFormData();
  const validationMessage = validateAccessFormData(formData);
  if (validationMessage) {
    toast.error(validationMessage);
    state.isLoading = false;
    return;
  }
  // Credentials are managed in Credential Update / View form
  const updateDomainAccessCredentialsParams: Partial<DomainCredentialModel & any> = {
    five9AgentUsername: formData?.five9AgentUsername,
    five9AgentPassword: formData?.five9AgentPassword,
    permissions: formData?.permissions
  };
  try {
    const updateDomainResponse = await updateDomainAccessCredentials(
      state.domainUuid,
      updateDomainAccessCredentialsParams
    );
    Vue.$log.debug({ updateDomainAccessCredentialsParams, updateDomainResponse });
    if (updateDomainResponse instanceof UserMessageError) {
      toast.error(updateDomainResponse.message);
      return;
    }
    if (!updateDomainResponse) {
      const errMessage =
        'Unable to update credentials. Please make sure a valid username, password and region were provided';
      toast.error(errMessage);
      return;
    }
    toast.success('Successfully Saved Domain');
    state.dialog = false;
    return;
  } catch (err) {
    Vue.$log.error(err);
    toast.error('Unable to update domain.');
  } finally {
    state.isLoading = false;
  }
};

const resetForms = () => {
  resetAccessForm();
};

const reset = () => {
  resetForms();
  Object.assign(state, emptyState);
};

const show = () => {
  state.dialog = true;
};

const edit = (item: DomainModel) => {
  show();
  Vue.$log.debug({ theThing: item });
  state.title = `Edit Credentials For ${item.label}`;
  state.mode = DialogMode.EDIT;
  const editFormOptions: FormOptions = { readonlyMode: false, editMode: true, item: item };
  state.domainUuid = item.uuid;
  setFormOptions(editFormOptions);
};

export default defineComponent({
  name: 'DomainDialogAccess',
  components: { DomainFormAccess },
  setup() {
    return {
      ...toRefs(state),
      reset,
      show,
      edit,
      DialogMode,
      domainFormMeta,
      domainFormAccess,
      domainFormProvisioning,
      updateDomainAccess,
      accessFormData
    };
  }
});
