var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("domain-page-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("refresh-button", {
                  staticClass: "mr-2",
                  attrs: { refreshFunction: _vm.refreshItems }
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: { small: "", light: "", tile: "" },
                    on: { click: _vm.addDomain }
                  },
                  [_vm._v("Add Domain")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Search by label",
                  outlined: "",
                  type: "text",
                  "append-icon": "mdi-magnify",
                  disabled: _vm.isLoading,
                  loading: _vm.isLoading
                },
                on: {
                  "click:append": _vm.searchTable,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchTable.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.searchInput,
                  callback: function($$v) {
                    _vm.searchInput = $$v
                  },
                  expression: "searchInput"
                }
              }),
              _vm.canUseDomainFilter
                ? _c(
                    "v-expansion-panels",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              "Filters " + _vm._s(_vm.queryParamFormattedFilter)
                            )
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-container",
                                [
                                  _vm._l(_vm.table.filter, function(
                                    filterItem,
                                    filterIndex
                                  ) {
                                    return _c(
                                      "v-row",
                                      { key: filterIndex },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-combobox", {
                                              attrs: {
                                                label: "Key",
                                                "single-line": "",
                                                "hide-details": "",
                                                items:
                                                  _vm.table
                                                    .filtersKeyAutocomplete
                                              },
                                              on: {
                                                keydown: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.searchTable.apply(
                                                    null,
                                                    arguments
                                                  )
                                                }
                                              },
                                              model: {
                                                value: filterItem.key,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    filterItem,
                                                    "key",
                                                    $$v
                                                  )
                                                },
                                                expression: "filterItem.key"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-combobox", {
                                              attrs: {
                                                label: "Value",
                                                "single-line": "",
                                                "hide-details": "",
                                                items:
                                                  _vm.table
                                                    .filtersValueAutocomplete,
                                                "item-text": "text",
                                                "item-value": "value",
                                                "return-object": false
                                              },
                                              on: {
                                                keydown: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.searchTable.apply(
                                                    null,
                                                    arguments
                                                  )
                                                }
                                              },
                                              model: {
                                                value: filterItem.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    filterItem,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "filterItem.value"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { staticClass: "col-1" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-6",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.table.filter.splice(
                                                      filterIndex,
                                                      1
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-delete")
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.addFilter()
                                                }
                                              }
                                            },
                                            [_vm._v("Add Filter")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canUseDomainFilter
                ? _c(
                    "v-btn",
                    {
                      attrs: { block: "", color: "primary" },
                      on: { click: _vm.searchTable }
                    },
                    [_vm._v("Search")]
                  )
                : _vm._e(),
              _c("v-data-table", {
                attrs: {
                  loading: _vm.isDomainLoading,
                  items: _vm.table.data,
                  headers: _vm.table.headers,
                  "items-per-page": _vm.table.itemsPerPage,
                  "hide-default-footer": "",
                  "item-key": "uuid",
                  "server-items-length": 1,
                  options: _vm.options
                },
                on: {
                  "update:options": function($event) {
                    _vm.options = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.label",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goToDomainWorkspace(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.label))]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.summary",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(" " + _vm._s(_vm.getObjectCount(item)) + " ")
                      ]
                    }
                  },
                  {
                    key: "item.mostRecentExtractJob.updatedAt",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        !_vm.domainHasRecentExport(item) &&
                        _vm.hasReadPermission(item)
                          ? _c(
                              "v-layout",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.isFetchingDomain(item) ||
                                        _vm.isExportLoading,
                                      color: "primary",
                                      "x-small": ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.createAndRunExportJob(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isFetchingDomain(item) ||
                                          _vm.isExportLoading
                                          ? "Snapshotting"
                                          : "New Snapshot"
                                      ) + " "
                                    ),
                                    _vm.isExportLoading ||
                                    _vm.isFetchingDomain(item)
                                      ? _c("v-progress-circular", {
                                          staticClass: "mx-2",
                                          attrs: {
                                            indeterminate: "",
                                            size: "10",
                                            width: "4"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : item.mostRecentExtractJob
                          ? _c("div", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.goToSnapshot(
                                        item.uuid,
                                        item.mostRecentExtractJob.uuid
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("job-status-icon", {
                                    attrs: { job: item.mostRecentExtractJob }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDbDate(
                                          item.mostRecentExtractJob.updatedAt
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.mostRecentLoadJob.updatedAt",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm.getLastLoadJobState(item) ===
                        _vm.LoadJobStateName.NEW
                          ? _c(
                              "span",
                              [
                                _vm.getLoadJobActionButtonToRender(item) ===
                                _vm.LoadJobActionButtonType.RUN_JOB
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          "x-small": ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToLoadJobDetailPage(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Run Job")]
                                    )
                                  : _vm.getLoadJobActionButtonToRender(item) ===
                                      _vm.LoadJobActionButtonType
                                        .CONFIGURE_JOB &&
                                    _vm.hasWritePermission(item)
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          "x-small": ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToLoadJobSetupPage(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Configure Job")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : item.mostRecentLoadJob !== undefined
                          ? _c("div", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.goToJob(
                                        item.uuid,
                                        item.mostRecentLoadJob.uuid
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("job-status-icon", {
                                    attrs: { job: item.mostRecentLoadJob }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDbDate(
                                          item.mostRecentLoadJob.updatedAt
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.accessLevel",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm.getAccessLevel(item) ===
                        _vm.DomainAccessLevelName.NONE
                          ? _c("span", [_vm._v("—")])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getAccessLevel(item) ===
                                      _vm.DomainAccessLevelName.NONE
                                      ? ""
                                      : _vm.getAccessLevel(item)
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-4",
                            attrs: { title: "Edit Domain", md: "" },
                            on: {
                              click: function($event) {
                                return _vm.editDomain(item)
                              }
                            }
                          },
                          [_vm._v("mdi-pencil")]
                        ),
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-4",
                            attrs: { title: "Edit Domain Access", md: "" },
                            on: {
                              click: function($event) {
                                return _vm.editDomainAccess(item)
                              }
                            }
                          },
                          [_vm._v("mdi-key")]
                        ),
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-4",
                            attrs: { title: "View Domain", md: "" },
                            on: {
                              click: function($event) {
                                return _vm.viewDomain(item)
                              }
                            }
                          },
                          [_vm._v("mdi-information-outline")]
                        ),
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { title: "Actions" } },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-dots-vertical")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "v-list",
                              { staticClass: "text-center" },
                              _vm._l(_vm.actionsMenu(item), function(
                                menuItem,
                                index
                              ) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: index,
                                    attrs: {
                                      disabled: _vm.isMenuItemDisabled(
                                        menuItem,
                                        item
                                      )
                                    },
                                    on: { click: menuItem.action }
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", {
                                          attrs: { color: menuItem.iconColor },
                                          domProps: {
                                            textContent: _vm._s(menuItem.icon)
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-content", [
                                      _vm._v(_vm._s(menuItem.title))
                                    ])
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "v-container",
                { staticClass: "text-center pt-2" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-pagination", {
                            attrs: {
                              length: _vm.table.pageCount,
                              "total-visible": 7
                            },
                            on: {
                              input: function($event) {
                                return _vm.getNextPage()
                              }
                            },
                            model: {
                              value: _vm.table.page,
                              callback: function($$v) {
                                _vm.$set(_vm.table, "page", $$v)
                              },
                              expression: "table.page"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              items: [
                                { text: 25, value: 25 },
                                { text: 50, value: 50 },
                                { text: 100, value: 100 },
                                { text: "All", value: -1 }
                              ],
                              "item-text": "text",
                              "item-value": "value",
                              label: "Rows per page"
                            },
                            model: {
                              value: _vm.itemsPerPageInput,
                              callback: function($$v) {
                                _vm.itemsPerPageInput = $$v
                              },
                              expression: "itemsPerPageInput"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("domain-dialog", { ref: "domainDialog" }),
          _c("domain-dialog-access", { ref: "domainDialogAccess" }),
          _c("export-job-setup-dialog"),
          _c("confirm")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }