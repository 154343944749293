




















import { defineComponent, ref, reactive, toRefs, computed } from '@vue/composition-api';
import HelpTooltipDialogs, {
  useHelpTooltipDialogs
} from '@/components/tooltips/HelpTooltipDialogs.vue';

const { showDomainListHelpTooltipDialog } = useHelpTooltipDialogs();
const state = reactive({
  showTooltip: false
});

const viewDialog = () => {
  showDomainListHelpTooltipDialog();
};

const fadeTooltip = () => {
  state.showTooltip = true;
  setTimeout(() => {
    state.showTooltip = false;
  }, 7000);
};

export default defineComponent({
  name: 'DomainPageToolbar',
  components: { HelpTooltipDialogs },
  setup(_, context) {
    const lgAndUp = computed(() => context.root.$vuetify.breakpoint.lgAndUp);
    const mdAndDown = computed(() => context.root.$vuetify.breakpoint.mdAndDown);
    const smAndDown = computed(() => context.root.$vuetify.breakpoint.smAndDown);

    return {
      ...toRefs(state),
      viewDialog,
      lgAndUp,
      mdAndDown,
      smAndDown
    };
  }
});
