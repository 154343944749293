
















































































































































































































import Vue from 'vue';
import router from '@/router';
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  computed,
  unref,
  ComputedRef
} from '@vue/composition-api';
import Confirm, { useConfirmation } from '@/components/confirmation/Confirmation.vue';
import { ExportStateName } from '@/module/api/export';
import { useDomainApi, DomainModel, DomainAccessLevelName } from '@/module/api/domain';
import DomainPageToolbar from '@/components/navigation/DomainPageToolbar.vue';
import { formatDbDate, usePagination } from '@/utils';
import ExportJobSetupDialog, {
  useExportJobSetupDialog
} from '@/views/app/export/ExportJobSetupDialog.vue';
import DomainDialog from '@/views/app/domains/DomainDialog.vue';
import DomainDialogAccess from '@/views/app/domains/DomainDialogAccess.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import {
  LoadJobStateName,
  LoadJobActionButtonType,
  getLoadJobSetupComponentNameByJobType,
  LoadJobType
} from '@/module/api/load-job';
import JobStatusIcon from '@/components/job-status-icon/JobStatusIcon.vue';
import { useAuth, userCanUseDomainFilter } from '@/module/auth';
import { DOMO_EXPIRATION_TTL_DAYS, isModelExpired } from '@/module/api/domo';

const { assertionContext } = useAuth();

const TABLE_PAGE_SIZE = 50;
const TABLE_SORT_BY = 'label';
const TABLE_SORT_DESC = false;

const itemsPerPageInput = ref(TABLE_PAGE_SIZE);
const sortByInput = ref(TABLE_SORT_BY);
const sortDescInput = ref(TABLE_SORT_DESC);
const searchInput = ref('');

const { add: createAndRunExportJob, isLoading: isExportLoading } = useExportJobSetupDialog();

const {
  items: domains,
  getItems: getDomains,
  isLoading: isDomainLoading,
  getDomainAccessCredentials,
  selectItem: selectDomain,
  hasReadPermission,
  hasWritePermission,
  isExportNewerThanLatestJobForDomain
} = useDomainApi();

const { areThereMorePages } = usePagination(TABLE_PAGE_SIZE);

const domainDialog = ref<any>(null);
const domainDialogAccess = ref<any>(null);

// Note: we can't use the pagination module's reset because it overwrites data
// which we cannot do.
const emptyPageableTable = () => {
  return {
    itemsPerPage: itemsPerPageInput.value,
    page: 1,
    pageCount: 1,
    sortBy: sortByInput.value,
    sortDesc: sortDescInput.value,
    search: searchInput.value
  };
};

class Filter {
  key!: string;
  value!: string;
}

const table = reactive({
  ...emptyPageableTable(),
  data: domains,
  loading: computed(() => {
    return isDomainLoading.value;
  }),
  notApplicableValue: '—',
  notConfiguredValue: 'Not Configured',
  filter: [] as Filter[],
  filtersKeyAutocomplete: [
    'filter[userId]',
    'filter[organizationId]',
    'filter[domainId]',
    'filter[domainName]',
    'filter[label]'
  ],
  filtersValueAutocomplete: computed(() => {
    return [
      { text: 'My User ID', value: assertionContext.value?.userId ?? '' },
      { text: 'My Org ID', value: assertionContext.value?.organizationId ?? '' }
    ];
  }),
  headers: [
    { text: 'Label', value: 'label' },
    { text: 'Objects', value: 'summary', sortable: false },
    { text: 'Last Snapshot', value: 'mostRecentExtractJob.updatedAt', width: 220 },
    { text: 'Last Job', value: 'mostRecentLoadJob.updatedAt', width: 220 },
    { text: 'Access', value: 'accessLevel', sortable: false, width: 75 },
    { text: 'Actions', value: 'actions', sortable: false, width: 180 }
  ] as any
});

const emptyState = () => {
  return {
    showRunExportDialog: false
  };
};
const state = reactive(emptyState());

const getObjectCount = (item: DomainModel): string | undefined => {
  const totalUnits: number | undefined = item.mostRecentExtractJob?.objectSummary?.total;
  if (!totalUnits) {
    return table.notApplicableValue;
  }
  return typeof totalUnits === 'number' ? totalUnits.toLocaleString('en-us') : totalUnits;
};

const getLastLoadJobState = (item: DomainModel): LoadJobStateName | undefined => {
  return item.mostRecentLoadJob?.state;
};

const domainHasRecentExport = (domain: DomainModel): boolean => {
  if (
    !domain.mostRecentExtractJob ||
    !domain.mostRecentExtractJob?.uuid ||
    domain.mostRecentExtractJob.uuid == ''
  ) {
    return false;
  } else {
    return true;
  }
};

const isFetchingDomain = (item: DomainModel) => {
  return item.mostRecentExtractJob?.state === ExportStateName.IN_PROGRESS;
};

const getLoadJobActionButtonToRender = (item: DomainModel): LoadJobActionButtonType | undefined => {
  if (item.mostRecentLoadJob?.state !== LoadJobStateName.NEW) {
    return undefined;
  } else if (item.mostRecentLoadJob?.wizards?.setup?.isComplete) {
    return LoadJobActionButtonType.RUN_JOB;
  } else {
    return LoadJobActionButtonType.CONFIGURE_JOB;
  }
};

const getAccessLevel = (item: DomainModel): string | undefined => {
  const accessLevel = item.access?.permissions;
  return accessLevel;
};

const editDomain = (item: DomainModel) => {
  domainDialog.value.edit(item);
};

const editDomainAccess = async (item: DomainModel) => {
  const credentials = await getDomainAccessCredentials(item.uuid);
  domainDialogAccess.value.edit({ ...item, ...credentials });
};

const viewDomain = (item: DomainModel) => {
  domainDialog.value.view({ ...item });
};

const addDomain = () => {
  domainDialog.value.add();
};

const isLatestExportJobRunning = (item: DomainModel): boolean => {
  return item?.mostRecentExtractJob?.state === ExportStateName.IN_PROGRESS;
};

const goToDomainWorkspace = (item: DomainModel) => {
  selectDomain(item.uuid);
  router.push({ name: 'DomainWorkspace', params: { uuid: item.uuid } });
};

const goToLoadJobDetailPage = (item: DomainModel) => {
  router.push({ path: `/domain/${item.uuid}/job/import/${item.mostRecentLoadJob?.uuid}` });
};

const goToLoadJobSetupPage = (item: DomainModel) => {
  if (!item.mostRecentLoadJob?.uuid || !item.mostRecentLoadJob?.type) {
    return;
  }

  const loadJobComponentName = getLoadJobSetupComponentNameByJobType(
    item.mostRecentLoadJob?.type || LoadJobType.IMPORT
  );
  if (!loadJobComponentName) {
    return;
  }
  router.push({
    name: loadJobComponentName,
    params: { uuid: item.uuid, jobUuid: item.mostRecentLoadJob?.uuid || '' }
  });
};

const addFilter = () => {
  table.filter.push({ key: '', value: '' });
};

const queryParamFormattedFilter = computed(() => {
  return table.filter.map((rec) => {
    return { [rec.key]: rec.value };
  });
});

const filterAsQueryParams = computed(() => {
  return table.filter.reduce((query: any, rec: any) => {
    if (!rec.key) {
      return query;
    }
    query[rec.key] = rec.value;
    return query;
  }, {});
});

const queryDomains = async (): Promise<void> => {
  Vue.$log.debug('DomainList: Query for domains');
  const query = { ...filterAsQueryParams.value } as any;
  query.page = table.page;
  query.limit = table.itemsPerPage;
  query[`sort[${table.sortBy}]`] = table.sortDesc ? -1 : 1;
  if (table.search) {
    query['filter[label][$regex]'] = table.search;
    query['filter[label][$options]'] = 'i';
  }
  const getDomainsParams = {
    query,
    raw: true
  };
  Vue.$log.debug('DomainList: params', getDomainsParams);
  const domainsResponse = await getDomains(getDomainsParams);
  if (table.page >= table.pageCount) {
    const areMorePages = areThereMorePages(domainsResponse);
    if (areMorePages) {
      table.pageCount = table.pageCount + 1;
    }
  }
};

const isAnyJobRunningForDomain = (domain: DomainModel) => {
  if (domain?.mostRecentExtractJob?.state === ExportStateName.IN_PROGRESS) {
    return true;
  }
  if (domain?.mostRecentLoadJob?.state === LoadJobStateName.IN_PROGRESS) {
    return true;
  }
  return false;
};

const isSnapshotButtonDisabledForDomain = (menuItem: any, domain: DomainModel) => {
  if (!hasReadPermission(domain)) {
    return true;
  }
  return isAnyJobRunningForDomain(domain);
};

const isNewJobButtonDisabledForDomain = (menuItem: any, domain: DomainModel) => {
  if (!hasWritePermission(domain)) {
    return true;
  }
  return isAnyJobRunningForDomain(domain) || !isExportNewerThanLatestJobForDomain(domain);
};

const isMenuItemDisabled = (menuItem: any, domain: DomainModel): boolean => {
  if (typeof menuItem.disabled !== 'function') {
    return menuItem.disabled;
  }
  return menuItem.disabled(menuItem, domain);
};

const initializeTableData = async () => {
  Object.assign(table, emptyPageableTable());
  await queryDomains();
};

const getNextPage = async () => {
  Vue.$log.debug('DomainList: getnextpage');
  await queryDomains();
};

// TODO: implement force refresh
const refreshItems = async () => {
  await initializeTableData();
};

const actionsMenu = (domain: DomainModel) => {
  const menu = [
    // TODO: { title: 'Download Object List', link: '' },
    {
      title: 'Snapshots',
      action: () => {
        router.push({
          name: 'WorkspaceExportJobList',
          params: { uuid: domain.uuid }
        });
      },
      icon: 'mdi-database',
      iconColor: '',
      description: 'View Snapshots',
      show: true,
      disabled: false
    },
    {
      title: 'Jobs',
      action: () => {
        router.push({
          name: 'WorkspaceImportJobList',
          params: { uuid: domain.uuid }
        });
      },
      icon: 'mdi-rocket',
      iconColor: '',
      description: 'View Jobs',
      show: true,
      disabled: false
    },
    {
      title: 'New Snapshot',
      action: () => createAndRunExportJob(domain),
      icon: 'mdi-database-arrow-down-outline',
      iconColor: 'green darken-3',
      description: 'Take a new Snapshot of the Domain',
      show: true,
      disabled: isSnapshotButtonDisabledForDomain
    },
    {
      title: 'Import to Domain',
      action: () => {
        router.push({
          name: 'ImportJobImportSetupWizard',
          params: { uuid: domain.uuid }
        });
      },
      icon: 'mdi-import',
      iconColor: 'red lighten-2',
      description: '',
      show: true,
      disabled: isNewJobButtonDisabledForDomain
    },
    {
      title: 'Restore Domain',
      action: () => {
        router.push({
          name: 'ImportJobRestoreSetupWizard',
          params: { uuid: domain.uuid }
        });
      },
      icon: 'mdi-restore',
      iconColor: 'blue darken-3',
      description: '',
      show: true,
      disabled: isNewJobButtonDisabledForDomain
    },
    {
      title: 'Reset Domain',
      action: () => {
        router.push({
          name: 'ImportJobResetSetupWizard',
          params: { uuid: domain.uuid }
        });
      },
      icon: 'mdi-alert-outline',
      iconColor: 'orange darken-1',
      description: '',
      show: true,
      disabled: isNewJobButtonDisabledForDomain
    }
  ];
  return menu.filter((v) => v.show);
};

const goToSnapshot = (domainUuid: string, jobUuid: string) => {
  router.push({
    name: 'WorkspaceExportJobDetail',
    params: {
      uuid: domainUuid,
      jobUuid: jobUuid
    }
  });
};

const goToJob = (domainUuid: string, jobUuid: string) => {
  router.push({
    name: 'WorkspaceImportJobDetail',
    params: {
      uuid: domainUuid,
      jobUuid: jobUuid
    }
  });
};

export default defineComponent({
  components: {
    DomainDialog,
    DomainDialogAccess,
    Confirm,
    ExportJobSetupDialog,
    RefreshButton,
    DomainPageToolbar,
    JobStatusIcon
  },
  name: 'DomainList',
  data() {
    return {
      options: {} as any
    };
  },
  watch: {
    options: {
      handler() {
        Vue.$log.debug('DomainList: options changed', this.options);
        sortByInput.value = this.options.sortBy[0] ?? TABLE_SORT_BY;
        sortDescInput.value = this.options.sortDesc[0] ?? TABLE_SORT_DESC;
        initializeTableData();
      },
      deep: true
    },
    itemsPerPageInput: function(newVal: number, oldVal: number): void {
      Vue.$log.debug(`DomainList: items per paged chnaged from ${oldVal} to ${newVal}`);
      // Note: emptyTableData will already have the newVal; no assignment needed.
      initializeTableData();
    }
  },
  setup() {
    selectDomain();
    refreshItems();
    return {
      ...toRefs(state),
      isLoading: computed(() => isExportLoading.value || isDomainLoading.value),
      itemsPerPageInput,
      searchInput,
      DomainAccessLevelName,
      LoadJobActionButtonType,
      LoadJobStateName,
      ExportStateName,
      formatDbDate,
      table,
      addDomain,
      isExportLoading,
      createAndRunExportJob,
      isLatestExportJobRunning,
      isDomainLoading,
      refreshItems,
      editDomainAccess,
      domainDialogAccess,
      getObjectCount,
      getLastLoadJobState,
      getLoadJobActionButtonToRender,
      goToLoadJobDetailPage,
      goToLoadJobSetupPage,
      getAccessLevel,
      domainDialog,
      editDomain,
      viewDomain,
      goToDomainWorkspace,
      isFetchingDomain,
      domainHasRecentExport,
      hasReadPermission,
      hasWritePermission,
      actionsMenu,
      goToSnapshot,
      goToJob,
      getNextPage,
      searchTable: () => {
        Vue.$log.debug(`DomainList: search for ${searchInput.value}`);
        // Note: emptyTableData will already have the search value; no
        // assignment needed.
        initializeTableData();
      },
      isMenuItemDisabled,
      queryParamFormattedFilter,
      addFilter,
      canUseDomainFilter: computed(() => {
        return userCanUseDomainFilter(assertionContext.value?.roles);
      })
    };
  }
});
